.browseButtonWrap {
    display: flex;
    justify-content: right;
    background: #ffffff;
    position: relative;
    padding: 6px 4px;
    /* box-shadow: 0 0 1pt 0.1pt #D3D3D3; */
    /* box-shadow: 0 0 1pt 0.5pt #D3D3D3; */
    border: 1px solid #CCCCCC;
    border-radius: 3px;
  }
  .browseButtonWrap span {
    display: none;
    color: #fff;
    background-color: #9e8959;
    outline: none;
    border: none;
    padding: 6px 4px;
    border-radius: 5px;
    font-size: 13px;
    cursor: pointer;
    box-shadow: 0 0 1pt 0.5pt #D3D3D3;
  
  }
  .customFileInput {
    color: transparent;
  
  }
  
  .customFileInput::-webkit-file-upload-button, 
   .customFileInput::file-selector-button{
    visibility: hidden;
  }
  .browseButtonWrap::after{
    position: absolute;
    right: 0px;
    top:50%;
    transform: translate(0%, -50%);
  content: "Browse";
  display: inline-block;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  background-color: #9e8959;
  outline: none;
  border: none;
  padding: 7px 4px;
  border-radius: 2px;
  font-size: 13px;
  cursor: pointer;
  outline: 0;
  height: 99%;
  pointer-events: none;
  
  }
  /* .customFileInput::after {
      position: absolute;
      right: 0px;
      top:50%;
      transform: translate(0%, -50%);
    content: "Browse";
    display: inline-block;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    user-select: none;
    color: #fff;
    background-color: #9e8959;
    outline: none;
    border: none;
    padding: 6px 4px;
    border-radius: 5px;
    font-size: 13px;
    cursor: pointer;
    outline: 0;
  
  } */
  .customFileInput:hover::after {
    border-color: black;
  }
  .customFileInput:active {
    /* outline: 0; */
  }
  .customFileInput:active::after {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  