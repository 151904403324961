.location-container{
position:absolute;
left: 79%;
top:1%;

width: 50px;
height: 50px;
background-color: #9E8959;
color: white;
display: flex;
justify-content: center;
align-items: center;
font-weight: bold;

}
.card-img{
    min-height: 50px;
    max-height: 150px;
}
.rowwidth{
    margin-right: calc(-5.5 * var(--bs-gutter-x)) !important;
}

.pagination {
    display: inline-block;
    cursor: pointer;
    justify-content: end;
    padding-right: 60px;
    
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 5px 10px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #434343;
    font-size: 15px;
  }
  .pagination a:hover{
    background-color: #525251;
    color: white;
  }
  .pagination a.active {
    background-color: #525251;
    color: white;
    border: 1px solid #525251;
  }
  
  .pagination a:hover:not(.active) 
  {
    background-color:#525251;
    color: white;
}
.page{
    display: flex;
    align-items: flex-end;
    justify-content: end;

    margin-top: 30px;

    
}
.active{
  background-color:#525251;
}