.formTitle {
    padding-top: 30px;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 30px;
  }
  .passwordLabel {
    font-size: 14px;
  }
  .inputtext {
    width: 100%;
    background: #f5f5f5;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.02);
    margin-top: 4px;
    padding: 8px;
    margin-right: 20px;
    border-radius: 3px;
  }
  .companySecValidate select {
   background: white;
  
   border: 1px solid #CCC;
    padding: 4px !important;
    border-radius: unset;
    height: 35px;
    border-radius: 3px;
    color: black;
    font-size: 12px;
  }
  .registerBackground {
    
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 10%;
  }
  .validateMessage {
    width: 100%;
  }
  .companySecValidate {
    width: 100%;
    padding: 0px 10px;
  }
  .contactPersonValidate {
    width: 100%;
    margin-bottom: 12px;
  }
  .condition {
    font-size: 12px;
  }
  .errorMessage {
    color: red;
    font-size: 12px;
  }
  .errorMessageSubmit{
    color: red; 
    font-size: 12px;
    text-align: center;
    margin-right: auto;
    align-self: center;
    margin: auto;
  }
  .registerContent {
    display: flex;
    justify-content: left;
  }
  .section1 {
    color: #fff;
    display: flex;
    flex-direction: column;
    padding-right: 10%;
  }
  .section1 h3 {
    margin-top: 30px;
  }
  .section1 p {
    margin-top: 30px;
  }
  .loopLogo {
    position: relative;
    aspect-ratio: 1/1;
    width: 40px;
  }
  .section2 {
    width: 100%;
  }
  .hybridForm {
    display: flex;
    margin: 0 auto;
    margin-top: 50px!important;
    width: 600px!important;
    background: #fff;
    border-radius: 20px;
    border: 1px solid #CCCCCC;
    
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
  }
  .tabType {
    display: flex;
    justify-content: center;
    padding: 10px 10px;
  }
  .tabTypeIndividual,
  .tabTypeCorporate {
    padding: 4px 40px;
    background: #d9d9d9;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .individualForm {
    display: flex;
    flex-direction: column;
  }
  .individualFormErrorMessage {
    width: 100%;
    color: red;
    text-align: center;
  }
  .formContent1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 35px;
    align-items: center;
  }
  
  .fields {
    display: flex;
    background-color: aqua;
  }
  .fieldSection1 .fieldSection2 {
  }
  .accept {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
  }
  .checkBox {
    width: 15px;
    height: 15px;
    background-color: #d9d9d9;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .accept input {
    cursor: pointer;
    display: none;
  }
  .accept p {
    font-size: 12px;
    font-weight: 400;
    padding-left: 5px;
  }
  .accept span {
    color: #9e8959;
    padding-left: 5px;
    cursor: pointer;
  }
  
  /* grid1 */
  .formContentGrid1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    padding: 10px;
  }
  
  .formContentGrid1 input {
    background: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.02);
    padding: 8px;
  }
  .formContentGrid1 span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.78);
  }
  .payAndPublish {
    background-color: #9e8959;
    cursor: pointer;
    color: #fff;
    width: 70%;
    font-weight: 550;
    border-radius: 2px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 0 auto;
    margin-top: 35px;
    font-size: 15px;
    display: block;
    transition:.5s ease;
    border: 1px solid #9e8959;
  }
  .payAndPublish:hover {
    background-color: #525251;
    border: 1px solid #525251;
    transform: scale(1.01);
  }
  
  .already {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
    padding-bottom: 20px;
  }
  .already span {
    color: #9e8959;
    padding-left: 5px;
    cursor: pointer;
  }
  
  .formContent2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* padding: 30px 10px 20px 10px; */
    align-items: center;
  }
  .formFlex {
    display: flex;
  }
  .companySec {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* padding-right: 20px; */
  }
  
  .mainflex {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  
  
  .companytext {
    width: 100%;
    border: 1px solid #CCC;
  outline:none;
    
    margin-top: 4px;
    padding: 8px;
    border-radius: 3px;
  }
  
  
  
  
  .textandlabel {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .textspan {
    width: 44%;
    padding-right: 20px;
    font-size: 14px;
  }
  .contactpersontitle {
    font-size: 14px;
    padding-bottom: 7px;
    color: #9e8959;
  }
  .filebrowse {
    /* width: 50%; */
    background-color: red;
  }
  .contactPersonDetail1 {
    display: inline;
  }
  .contactPersonDetail2 {
    display: none;
  }
  /* grid2 */
  .formContentGrid2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 2fr 3fr auto;
    column-gap: 10px;
    row-gap: 10px;
    padding: 10px;
  }
  .formContentGrid2 span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.78);
  }
  .formContentGrid2 input[type="text"]::placeholder,
  .formContentGrid2 input[type="email"]::placeholder {
    color: #d9d9d9;
  }
  .formContentGrid2 input[type="text"],
  .formContentGrid2 input[type="email"] {
    background: #f5f5f5;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.02);
    margin-top: 4px;
    padding: 8px;
    /* margin-bottom: 2px; */
  }
  .overlappedFields {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .overlappedFields input {
    width: 100%;
    /* height: 48%; */
  }
  .fileSelection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 4px;
  
    position: relative;
  }
  
  
  .fileSelection .browseButtonWrap {
    display: flex;
    justify-content: right;
    background: #f5f5f5;
  }
  .fileSelection .browseButtonWrap span {
    color: #fff;
    background-color: #9e8959;
    outline: none;
    border: none;
    padding: 6px 4px;
    border-radius: 5px;
    font-size: 13px;
    cursor: pointer;
    box-shadow: 0 0 1pt 0.5pt #D3D3D3;
  
  }
  .fileSelected {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(204, 204, 204, 0.83);
    border-radius: 4px;
    margin-top: 8px;
    padding: 4px 4px;
    align-items: center;
  }
  .fileSelected span {
    font-size: 12px;
  }
  .actionButtons {
    display: flex;
    justify-content: center;
  }
  
  .actionButtons span {
    margin: 0 1px;
    color: #fff;
    background-color: #525251;
    padding: 3px 9px;
    border: 0;
    border-radius: 4px;
    font-size: 10px;
    cursor: pointer;
  }
  .formContentFlex1 {
    display: flex;
    flex-wrap: wrap;
  }
  .formContentWrapDiv1,
  .formContentWrapDiv2 {
    display: flex;
    justify-content: space-between;
    width: 50%;
    /* padding: 5px 0px 26px 5px; */
    padding: 5px 10px 20px 5px;
    /* padding:5px;
    padding-right: 24px; */
    align-items: center;
  }
  
  .formContentWrapDiv1 span,
  .formContentWrapDiv2 span {
    /* width: 35%; */
    width: 50%;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.78);
    font-size: 14px;
  }
  
  .formContentWrapDiv1 input,
  .formContentWrapDiv2 input {
    width: 100%;
    background: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.02);
    padding: 8px;
  }
  .formContentWrapDiv1 select,
  .formContentWrapDiv2 select {
    
    width: 100%;
    background: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.02);
    padding: 8px;
  }
  /* @media screen and (max-width: 1150px) {
  }
  /* @media screen and (max-width: 1120px) and (min-width: 901px) {
    .mainflex {
      flex-wrap: wrap;
    }
    .companySec {
      width: 100%;
    }
  
    .textspan {
      width: 50%;
      font-size: 10px;
    }
  
   
    .contactDet {
      width: 40%;
    }
    .contacttext {
      width: 100%;
    }
    .fileSelection {
      width: 50%;
    }
    .mainflex textarea {
      width: 50%;
    }
    .contactpersontitle {
      padding-right: 66px;
    }
  } */ 
  @media screen and (max-width: 660px) {
    .inputtext {
      width: 100%;
      margin-right: 0;
    }
    /* .formContent2 {
      padding: 10px 20px 20px 20px;
    } */
    .payAndPublish{
      width: 100%;
    }
    
    .companySecValidate {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0;
    }
    .mainflex {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
   
    .contactpersontitle {
      padding-top: 20px;
    }
    /* grid1 */
    .formContentGrid1 {
      grid-template-columns: 100%;
      width: 100%;
    }
    .companySec {
      padding-right: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  
    .fileSelection {
      width: 100%;
      max-width: none;
    }
    .textspan {
      width: 100%;
    }
    .companytext {
      width: 100% !important;
    }
  
    .formContent1 {
      /* width: 100%; */
    }
  .textandlabel{
    width: 100%;
  }
    .contactPersonDetail1 {
      display: none;
    }
    .contactPersonDetail2 {
      display: inline;
    }
    /* grid1 */
    .formContentGrid2 {
      grid-template-columns: 100%;
      grid-template-rows: none;
  
      width: 100%;
    }
    .corporateForm {
      width: 100%;
      padding: 8px;
    }
    .formContentFlex1 {
      flex-direction: column;
      width: 100%;
    }
    .formContentWrapDiv1,
    .formContentWrapDiv2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
  
    .formContentWrapDiv1 span,
    .formContentWrapDiv2 span {
      width: 100%;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.78);
    }
  
    .formContentWrapDiv1 input,
    .formContentWrapDiv2 input {
      width: 100%;
      background: #f5f5f5;
      border: 1px solid rgba(0, 0, 0, 0.02);
      padding: 8px;
    }
    .formContentWrapDiv1 select,
    .formContentWrapDiv2 select {
      width: 100%;
      background: #f5f5f5;
      border: 1px solid rgba(0, 0, 0, 0.02);
      padding: 8px;
    }
  }
  @media screen and (max-width: 425px) {
    .tabType {
      display: flex;
      width: 100%;
      /* background-color: aqua; */
    }
    .tabTypeIndividual,
    .tabTypeCorporate {
      padding: 4px 20px;
      background: #d9d9d9;
      cursor: pointer;
      border-radius: 5px;
      width: 100%;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 385px) {
    .tabType {
      display: flex;
      width: 100%;
      /* background-color: aqua; */
    }
    .tabTypeIndividual,
    .tabTypeCorporate {
      padding: 4px 10px;
      background: #d9d9d9;
      cursor: pointer;
      border-radius: 5px;
      width: 100%;
      text-align: center;
      font-size: 15;
    }
    .hybridForm {
      padding: 10px;
    }
  }
  