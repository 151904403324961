.fileSelected {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(204, 204, 204, 0.83);
    border-radius: 4px;
    margin-top: 8px;
    padding: 4px 4px;
    align-items: center;
  }
  .fileSelected:first-child{
    margin-top: 4px;

  }
  .fileSelected span {
    font-size: 12px;
  }

  .actionButtons {
    display: flex;
    justify-content: center;
  }
  
  .actionButtons span {
    margin: 0 1px;
    color: #fff !important;
    background-color: #525251;
    padding: 3px 9px;
    border: 0;
    border-radius: 2px;
    font-size: 10px;
    cursor: pointer;
  }
  @media screen and (max-width: 1120px){
    .fileSelected span {
      font-size: 8px;
    }
  }
  @media screen and (max-width: 660px){
    .fileSelected span {
      font-size: 10px;
    }
  }