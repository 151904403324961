.react-confirm-alert {
  .custom-ui {
    width: 352px;
    height: 200px;
    padding: 10px;

    background: #ffffff;
    box-shadow: 2px 3px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    .msgContainer {
      width: 100%;
      margin: auto;
      padding: 20px;
      justify-content: center;
      .message {
        font-size: medium;
      }
    }
    .row {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      margin: auto;
      .button {
        width: 100px;
        height: 35px;
        align-items: flex-end;
        
      }
      .btn1 {
        margin-right: auto;
      }
      .btn2 {
        margin-left: auto;
      }
    }
  }
}
